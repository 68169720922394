import { Navigate } from "react-router-dom";

import RootLayout from "../RootLayout";
import { useAuth } from "../contexts/AuthContext";

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) return <Navigate to="/login" />;

 

  return <RootLayout />;
};

export default PrivateRoutes;
