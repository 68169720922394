import { useQuery } from '@tanstack/react-query';
import { getCallHistoryDetail } from '../../../services/commonController';
import { queryKey } from '../../../constants/queryKey';

export const useCallHistoryDetail = (userId: number, clientCallId: number, option:any) => {
  return useQuery({
    queryKey: [queryKey.GET_CALL_HISTORY_DETAIL, userId, clientCallId],
    queryFn: () => getCallHistoryDetail(userId, clientCallId).then(res => res.data),
    ...option
  });
};