import React, { useState } from "react";
import { Skeleton, Table, Tooltip, Row, Pagination, Col } from "antd";

import AppLayout from "../Layout";
import { getUserData } from "../../utils";
import { FinAdvanceFilter } from "../FinFilter";
import { ChatDetailsModal } from "./chatDetailsModal";
import { useChatHistory } from "../../reactQuery/hooks/agentHooks/useChatHistory";
import { FilterOutlined } from "@ant-design/icons";


export default function ChatHistory({ response }: any) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
    const [searchByClientName, setSearchByClientName] = useState("");
    const [searchById, setSearchByClientId] = useState("");
    const [searchByStatus, setSearchByStatus] = useState("");
    const userInfo = getUserData();



    const chatTableColumn: any = [
        {
            title: "Chat Id",
            dataIndex: "chatWindowId",
            render: (response: string) => (
                <span>{response || <Tooltip title="-">-</Tooltip>}</span>
            ),
        },
        {
            title: "Client Name",
            dataIndex: "clientName",
            render: (response: string) => (
                <span>{response || <Tooltip title="-">-</Tooltip>}</span>
            ),
        },
        {
            title: "Product",
            dataIndex: "productName",
            render: (response: string) => (
                <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
            ),
        },
        {
            title: "Sub Product",
            dataIndex: "subProductName",
            render: (response: string) => (
                <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (response: string) => (
                <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
            ),
        },
        {
            title: "Date",
            dataIndex: "date",
            render: (response: string) => (
              <span>{response ? new Date(response).toLocaleDateString() : <Tooltip title="Not Available">N/A</Tooltip>}</span>
            ),
          },
        {
            title: "Time",
            dataIndex: "time",
            render: (response: string) => (
                <span>{response ? new Date(response).toLocaleTimeString() : <Tooltip title="Not Available">N/A</Tooltip>}</span>
            ),
        },
        {
            title: "User Sentiment",
            dataIndex: "userSentiments",
            render: (response: string) => (
                <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
            ),
        },
    ];

    const chatHistoryPayload = {
        userId: userInfo?.userId,
        searchByClientName: searchByClientName,
        searchById: searchById,
        searchByStatus: searchByStatus,
        pageNo: currentPage,
        pageSize,
    }
    const { data, isLoading, isFetched }: any = useChatHistory(chatHistoryPayload);
    const chatHistoryList = data?.data || [];
    const handleRowClick = (record: any) => {
        setSelectedRow(record);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedRow(null);
    };
    const handleOnChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPageSize(pageSize);

    }
    const toggleAdvanceFilter = () => {
        setIsAdvanceFilterOpen(!isAdvanceFilterOpen)
    };
    const handleSearchQueries = (clientId: string, clientName: string, status: string) => {
        setSearchByStatus(status)
        setSearchByClientId(clientId)
        setSearchByClientName(clientName)
    }
    return (
        <AppLayout moduleName="Chat History">
            <>
                <Row className="flex justify-end">
                    <Col className="p-2 pr-4 flex gap-3 pb-4">
                        <Tooltip title={isAdvanceFilterOpen ? "Close Filter" : "Open Filter"}>
                            <FilterOutlined
                                className="cursor-pointer text-lg"
                                onClick={toggleAdvanceFilter}
                            />
                        </Tooltip>

                    </Col>
                </Row>
                {isAdvanceFilterOpen && <Row className="pb-4">
                    <FinAdvanceFilter
                        idLabel="Chat Id"
                        handleSearchQueries={handleSearchQueries}
                        toggleAdvanceFilter={toggleAdvanceFilter}
                    />
                </Row>}

                {isLoading || !isFetched ? (
                    <Skeleton loading={isLoading || !isFetched} active paragraph={{ rows: 12 }} />
                ) : (
                    <>

                        <Table
                            loading={isLoading || !isFetched}
                            columns={chatTableColumn}
                            dataSource={chatHistoryList}
                            size="middle"
                            pagination={false}
                            onRow={(record) => ({
                                onClick: () => handleRowClick(record),
                            })}
                        />

                        <Row justify="center" className="pt-4">
                            <Pagination
                                total={data?.totalResults}
                                pageSize={pageSize}
                                current={currentPage}
                                showSizeChanger
                                onChange={handleOnChange}
                            />
                        </Row>
                    </>

                )}

            </>

            <ChatDetailsModal
                selectedRow={selectedRow}
                visible={isModalVisible}
                onClose={handleModalClose}
                userId={userInfo?.userId}
                chatWindowId={selectedRow?.chatWindowId}
            />
        </AppLayout>
    );
}