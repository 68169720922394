import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setAxiosConfig } from "./services/axiosConfig";
import { AuthProvider } from "./contexts/AuthContext";
import { GlobalStateProvider } from "./contexts/StoreContext";
import router from "./routing/routes";




const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
setAxiosConfig();
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: { colorPrimary: "3E66F2" },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <GlobalStateProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </GlobalStateProvider>
      </QueryClientProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
