import { useQuery } from '@tanstack/react-query';
import { getChatHistory } from '../../../services/agentController';
import { queryKey } from '../../../constants/queryKey';


interface Payload {
  userId: string;
  searchByClientName?: string;
  searchById?: any;
  searchByStatus?: string;
  pageNo: number;
  pageSize: number;
}
export const useChatHistory = (payload: Payload, options?:any) => {
  const userId = payload.userId;
  return useQuery({
    queryKey: [queryKey.GET_CHAT_HISTORY, userId, payload.pageNo, payload.pageSize, payload.searchById, payload.searchByClientName, payload.searchByStatus],
    queryFn: () => getChatHistory(payload).then(res => res.data),
    ...options
  });
};