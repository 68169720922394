export const queryKey = {
    GET_SIDEBAR_MENU : "sidebarMenu",
    GET_CHAT_HISTORY : "chatHistory",
    GET_CHAT_HISTORY_DETAIL : "chatHistoryDetail",
    GET_CALL_HISTORY : "callHistory",
    GET_CALL_HISTORY_DETAIL : "callHistoryDetail",
    GET_STATUS_LIST : "statusList",
    GET_ACTIVE_CLIENTS_KB : "activeClientsKB",
    GET_CLOSED_CLIENTS_KB : "closedClientsKB",
}
