import AppLayout from "../Layout";
import { useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Table, Tooltip, Skeleton, Row, Pagination, Col } from "antd";
import { useCallHistoryList } from "../../reactQuery/hooks/common/useCallHistory";
import {CallDetailsModal} from "./CallDetailsModal";
import { getUserData } from "../../utils";
import { FilterOutlined } from "@ant-design/icons";
import { FinAdvanceFilter } from "../FinFilter";

const callTableColumn: any = [
    { title: "Call Id", dataIndex: "clientCallId" },
    {
        title: "Client Name",
        dataIndex: "clientName",
        render: (response: string) => (
            <span>{response || <Tooltip title="-">-</Tooltip>}</span>
        ),
    },
    {
        title: "Call Duration",
        dataIndex: "callDurationInSeconds",
        render: (response: string) => <span>{response}</span>
    },
    {
        title: "Product",
        dataIndex: "productName",
        render: (response: string) => (
            <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
        ),
    },
    {
        title: "Sub Product",
        dataIndex: "subProductName",
        render: (response: string) => (
            <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (response: string) => (
            <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
        ),
    },
    {
        title: "Date",
        dataIndex: "date",
        render: (response: string) => (
            <span>{new Date(response).toLocaleDateString() || <Tooltip title="Not Available">N/A</Tooltip>}</span>
        ),
    },
    {
        title: "Time",
        dataIndex: "time",
        render: (response: string) => (
            <span>{new Date(response).toLocaleTimeString() || <Tooltip title="Not Available">N/A</Tooltip>}</span>
        ),
    },
    {
        title: "User Sentiment",
        dataIndex: "userSentiments",
        render: (response: string) => (
            <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
        ),
    },

];

export default function CallHistory({ response }: any) {
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [clientCallId, setClientCallId] = useState('');
    const [clientName, setClientName] = useState("")
    const [status, setStatus] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [callId, setCallId] = useState("");
    const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
    const [searchByClientName, setSearchByClientName] = useState("");
    const [searchById, setSearchById] = useState("");
    const [searchByStatus, setSearchByStatus] = useState("");



    const userInfo = getUserData()
    const userId = userInfo.userId;

    const { data, isLoading, error } = useCallHistoryList(userId, searchByClientName, searchById, searchByStatus, pageNo, pageSize);

    if (error) return <div>Error: {error.message}</div>;
    const handleRowClick = (record: any) => {
        setSelectedRow(record);
        setIsModalVisible(true);
    };
    const handleOnChange = (page: number, pageSize?: number) => {
        setPageNo(page);
        setPageSize(pageSize || 10);
    }
    const toggleAdvanceFilter = () => {
        setIsAdvanceFilterOpen(!isAdvanceFilterOpen)
    };
    const handleSearchQueries = (id: string, clientName: string, status: string) => {
        setSearchByStatus(status)
        setSearchById(id)
        setSearchByClientName(clientName)
    }
    return (
        <AppLayout moduleName="Call History">
            <>
                <>

                    <Row className="flex justify-end">
                        <Col className="p-2 pr-4 flex gap-3 pb-4">
                            <Tooltip title={isAdvanceFilterOpen ? "Close Filter" : "Open Filter"}>
                                <FilterOutlined
                                    className="cursor-pointer text-lg"
                                    onClick={toggleAdvanceFilter}
                                />
                            </Tooltip>

                        </Col>
                    </Row>
                    {isAdvanceFilterOpen && <Row className="pb-4">
                        <FinAdvanceFilter
                            idLabel="Call Id"
                            handleSearchQueries={handleSearchQueries}
                            toggleAdvanceFilter={toggleAdvanceFilter}
                        />
                    </Row>}
                </>

                {
                    isLoading ?
                        <Skeleton active paragraph={{ rows: 14 }} /> :
                        <>
                            <Table
                                columns={callTableColumn}
                                dataSource={data?.data || []}
                                size="middle"
                                pagination={false}
                                rowKey="clientCallId"
                                onRow={(record) => ({
                                    onClick: () => handleRowClick(record),
                                })}
                            />
                            <Row justify="center" className="pt-4">
                                <Pagination
                                    total={data?.totalResults}
                                    pageSize={pageSize}
                                    current={pageNo}
                                    showSizeChanger
                                    onChange={handleOnChange}
                                />
                            </Row>

                            <CallDetailsModal
                                visible={isModalVisible}
                                onClose={() => setIsModalVisible(false)}
                                userId={userInfo.userId}
                                clientCallId={selectedRow?.clientCallId}
                            />
                        </>
                }
            </>



        </AppLayout>
    );
}