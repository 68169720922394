export const finEndpoints = {
  AGENT_SIDEBAR_MENU: "common/fin-voice-main-menu?roleId=",
  CHAT_HISTORY_LIST: "agent/chat-history-list",
  CHAT_HISTORY_DETAIL: "agent/chat-history-detail?userId=",
  CALL_HISTORY_LIST: "agent/call-history-list",
  CALL_HISTORY_DETAIL: "agent/call-history-detail?userId=",
  STATUS_LIST: "common/status-list",
  ACTIVE_CLIENTS: "knowledge-base/active-clients?userId=",
  CLOSE_CLIENT: "knowledge-base/closed-clients?userId=",
};
