import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../../../constants/queryKey';
import { getClosedClients } from '../../../services/knowledgeBaseController';

export const useClosedClients = (userId: number, activeKey:string, pageNo:number, pageSize:number) => {
    return useQuery({
        queryKey: [queryKey.GET_CLOSED_CLIENTS_KB, userId],
        queryFn: () => getClosedClients(userId, pageNo, pageSize).then(res => res.data),
        enabled: activeKey === "2"
    });
};