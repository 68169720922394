import React, { useRef } from "react";
import { Modal, Row, Col, Card, Typography, Skeleton } from "antd";
import ReactAudioPlayer from "react-audio-player";
import { useCallHistoryDetail } from "../../reactQuery/hooks/common/useCallHistoryDetails";

interface CallDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  userId: number;
  clientCallId: number;
}

interface Transcription {
  name: string;
  text: string;
  createdTime: string;
}

interface CallDetails {
  chatWindowId: number;
  clientCallId: number;
  callDurationInSeconds: number;
  recordingUrl: string;
  clientName: string;
  productId: number;
  productName: string;
  subProductId: number;
  subProductName: string;
  date: string;
  time: string;
  status: string;
  userSentiments: string;
  summary: string | null;
  transcription: Transcription[] | null;
}

export const CallDetailsModal: React.FC<CallDetailsModalProps> = ({ visible, onClose, userId, clientCallId }) => {
  const { data, isLoading }: any = useCallHistoryDetail(userId, clientCallId, {
    enabled: visible,
  });

  const callDetails: CallDetails = data?.data || ({} as CallDetails);
  const audioPlayerRef = useRef<any>(null);

  const handleClose = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audioEl.current.pause();
    }
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      footer={null}
      width="50%"
      style={{
        top: 0,
        right: 0,
        position: "fixed",
        height: "100vh",
        margin: 0,
        maxWidth: "100%",
      }}
      bodyStyle={{ height: "100vh", overflowY: "auto" }}
    >
      <Card title="Call Details">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 12 }} />
        ) : (
          <>
            <Row>
              <Col span={12} className="p-1">
                <p><strong>Call Id:</strong> {callDetails.clientCallId}</p>
              </Col>
              <Col span={12} className="p-1">
                <p><strong>Client Name:</strong> {callDetails.clientName}</p>
              </Col>
              <Col span={12} className="p-1">
                <p><strong>Product:</strong> {callDetails.productName}</p>
              </Col>
              <Col span={12} className="p-1">
                <p><strong>Sub Product:</strong> {callDetails.subProductName}</p>
              </Col>
              <Col span={12} className="p-1">
                <p><strong>Status:</strong> {callDetails.status}</p>
              </Col>
              <Col span={12} className="p-1">
                <p><strong>Date:</strong> {new Date(callDetails.date).toLocaleDateString()}</p>
              </Col>
              <Col span={12} className="p-1">
                <p><strong>Time:</strong> {new Date(callDetails.time).toLocaleTimeString()}</p>
              </Col>
              <Col span={12} className="p-1">
                <p><strong>User Sentiment:</strong> {callDetails.userSentiments}</p>
              </Col>
              <Col span={24} className="p-1">
                <Typography.Text><strong>Summary:</strong></Typography.Text>
                <p>{callDetails.summary || "No summary available"}</p>
              </Col>
              <Col span={24} className="p-1">
                <Typography.Text><strong>Recording:</strong></Typography.Text>
                {callDetails.recordingUrl ? (
                  <ReactAudioPlayer ref={audioPlayerRef} src={callDetails.recordingUrl} controls />
                ) : (
                  <span>No recording available</span>
                )}
              </Col>
              <Col span={24}>
                <Typography.Text><strong>Transcription:</strong></Typography.Text>
                {callDetails.transcription && callDetails.transcription.length > 0 ? (
                  callDetails.transcription.map((item, index) => (
                    <div key={index}>
                      <Typography.Text><strong>{item.name || "NA"}:</strong> {item.text || "NA"}</Typography.Text>
                      <Typography.Text className="block text-[gray]">{new Date(item.createdTime).toLocaleString() || "NA"}</Typography.Text>
                    </div>
                  ))
                ) : (
                  <Typography.Text className="block">No transcription data available.</Typography.Text>
                )}
              </Col>
            </Row>
          </>
        )}
      </Card>
    </Modal>
  );
};
