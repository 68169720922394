import axios from "axios";
import {
  AGENT_ADMISSION_STATUS_ENDPOINT,
  AGENT_CALL_RESPONSE_ENDPOINT,
  AGENT_CASE_DETAILS_ENDPOINT,
  AGENT_TRANSCRIPT_ENDPOINT,
  INITIATE_CALL_ENDPOINT,
  MARK_AS_PAID_ENDPOINT,
  SUBMIT_CALL_RESPONSE,
  PAST_CALL_DETAILS,
} from "../constants/endpoints";

import {
  AGENT_ACTIVE_CASES_ENDPOINT,
  AGENT_COMPLETED_CASES_ENDPOINT,
  AGENT_PENDING_CASES_ENDPOINT,
} from "../constants/endpoints";
import { finEndpoints } from "../constants/finEndpoints";

type CasesBodyType = {
  dpdStatus: string;
  endDate: string;
  maxDueAmount: string;
  maxLoanAmount: string;
  minDueAmount: string;
  minLoanAmount: string;
  nbfc: string;
  page: number;
  size: number;
  startDate: string;
  status: string;
};

type SubmitCallResponsePayloadTypes = {
  logId: string | "";
  responseId: string | "";
  action: string | "";
};
export function getAgentCaseDetails(id: string, bucketName: string) {
  console.log("getAgentCaseDetails ", bucketName);
  const endpoint = `${AGENT_CASE_DETAILS_ENDPOINT}${id}&bucketName=${bucketName}`;
  return axios.get(endpoint);
}

export async function getAgentActiveCases(body: CasesBodyType) {
  const res = await axios.post(AGENT_ACTIVE_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}

export async function getAgentPendingCases(body: CasesBodyType) {
  const res = await axios.post(AGENT_PENDING_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}
export async function getAgentCompletedCases(body: CasesBodyType) {
  const res = await axios.post(AGENT_COMPLETED_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}
type AddmissionStatusPayloadTypes = {
  admissionStatus: string;
  caseId: string;
};
export function postAdmissionStatus(payload: AddmissionStatusPayloadTypes) {
  return axios.post(AGENT_ADMISSION_STATUS_ENDPOINT, payload);
}

export function initiateCall(caseId: string) {
  const endpoint = `${INITIATE_CALL_ENDPOINT}${caseId}`;
  return axios.get(endpoint);
}

export function pastCallDetails(caseId: string) {
  const endpoint = `${PAST_CALL_DETAILS}${caseId}`;
  return axios.get(endpoint);
}

export function getCallResponseList(caseId: string) {
  const endpoint = `${AGENT_CALL_RESPONSE_ENDPOINT}${caseId}`;
  return axios.get(endpoint);
}

export function postCallResponse(payload: SubmitCallResponsePayloadTypes) {
  return axios.post(SUBMIT_CALL_RESPONSE, payload);
}

export function markAsPaid(caseId: string) {
  const endpoint = `${MARK_AS_PAID_ENDPOINT}${caseId}`;
  return axios.get(endpoint);
}

export function getTranscript(callId: string, caseId: string) {
  const endpoint = `${AGENT_TRANSCRIPT_ENDPOINT}${callId}&caseId=${caseId}`;
  return axios.get(endpoint);
}

interface ChatHistoryPayload {
  userId: string;
  searchByClientName?: string;
  searchById?: number;
  searchByStatus?: string;
  pageNo: number;
  pageSize: number;
}
export function getChatHistory(payload: ChatHistoryPayload) {
  const {
    userId,
    pageNo = 1,
    pageSize = 10,
    searchById="",
    searchByClientName="",
    searchByStatus="",
  } = payload;
  return axios.post(finEndpoints.CHAT_HISTORY_LIST, {
    userId,
    pageNo,
    pageSize,
    searchById,
    searchByClientName,
    searchByStatus,
  });
}
