import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  DashboardOutlined,
  SettingOutlined,
  HistoryOutlined,
  MessageOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  BarChartOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { useGlobalStore } from "../../contexts/StoreContext";
import { smallLogo } from "../../utils/image";
import bigLogo from "../../assets/logo.svg";
import { useSidebarMenu } from "../../reactQuery/hooks/sidebar/useSidebarMenu";
import { getUserData } from "../../utils";

const { Sider } = Layout;

const bgStyle = { backgroundColor: "#7CB9E8", minHeight: "100vh" };
type SidebarTypes = { collapsed: false | true; };

const Sidebar: any = (props: SidebarTypes) => {
  const { collapsed } = props;
  const { state, dispatch } = useGlobalStore();
  const navigate = useNavigate();
  const userInfo = getUserData();

  const { data } = useSidebarMenu(userInfo?.roleId);

  const getIcon = (menuName: string) => {
    switch (menuName) {
      case "Knowledge Base":
        return <BookOutlined />;
      case "Call History":
        return <HistoryOutlined />;
      case "Chat History":
        return <MessageOutlined />;
      case "Leads Converted":
        return <CheckCircleOutlined />;
      case "Leads Closed":
        return <CloseCircleOutlined />;
      case "Policy Details":
        return <FileTextOutlined />;
      case "Total Outputs":
        return <BarChartOutlined />;
      default:
        return <SettingOutlined />;
    }
  };

  const items = data?.data?.map((menuItem: any) => ({
    key: menuItem.menuId.toString(),
    icon: getIcon(menuItem.menuName),
    label: menuItem.menuName,
  })) || [];

  const handleClick = ({ key }: any) => {
    localStorage.setItem("activeSidebarKey", key);
    dispatch({ type: "SET_SIDEBAR_ACTIVE_KEY", payload: key });
    const actionMap: { [key: string]: () => void } = {
      "1": () => navigate("/"),
      "2": () => navigate("/call-history"),
      "3": () => navigate("/chat-history"),
      "4": () => navigate("/leads-converted"),
      "5": () => navigate("/leads-closed"),
      "6": () => navigate("/query-addressed"),
      "7": () => navigate("/policy-details"),
      "8": () => navigate("/total-outputs"),
    };
    const action = actionMap[key];
    if (action) {
      action();
    }
  };

  const activeSidebarKey = localStorage.getItem("activeSidebarKey") || "1"; // Default to "1" if null

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} style={bgStyle}>
      {collapsed ? (
        <div className="" style={{ padding: "1.5rem" }}>
          <img src={smallLogo} alt="loading..." />
        </div>
      ) : (
        <div className="" style={{ padding: "2rem" }}>
          <img src={bigLogo} alt="loading..." />
        </div>
      )}

      <Menu
        defaultSelectedKeys={[activeSidebarKey]}
        selectedKeys={[activeSidebarKey]}
        className="text-left"
        theme="light"
        mode="inline"
        items={items}
        style={bgStyle}
        onClick={(e: any) => handleClick(e)}
      />
    </Sider>
  );
};

export default Sidebar;