import React from "react";
import { Modal, Row, Col, Card, Typography, Skeleton } from "antd";
import { useChatHistoryDetail } from "../../reactQuery/hooks/common/useChatHistoryDetails";

interface ChatDetailsModalProps {
    visible: boolean;
    onClose: () => void;
    selectedRow: ChatDetails | null;
    userId: string;
    chatWindowId: string;
}

interface Transcription {
    name: string;
    text: string;
    createdTime: string;
}

interface ChatDetails {
    chatWindowId: number;
    clientName: string;
    date: string;
    productId: number;
    productName: string;
    status: string;
    subProductId: number;
    subProductName: string;
    summary: string;
    time: string;
    transcription: Transcription[];
    userSentiments: string;
}

export const ChatDetailsModal: React.FC<ChatDetailsModalProps> = ({ visible, onClose, selectedRow, userId, chatWindowId }) => {
    const { data, isLoading }: any = useChatHistoryDetail(userId, chatWindowId, {
        enabled: visible,
    });

    const chatDetails: ChatDetails = data?.data || ({} as ChatDetails);
    const {
        clientName,
        date,
        productName,
        status,
        subProductName,
        summary,
        time,
        transcription,
        userSentiments,
    } = chatDetails;

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            footer={null}
            width="50%"
            style={{
                top: 0,
                right: 0,
                position: "fixed",
                height: "100vh",
                margin: 0,
                maxWidth: "100%",
            }}
            bodyStyle={{ height: "100vh", overflowY: "auto" }}
        >
            <Card title="Chat Details">
                {isLoading ? (
                    <Skeleton active paragraph={{ rows: 12 }} />
                ) : (
                    selectedRow && (
                        <>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <p><strong>Chat Id:</strong> {chatWindowId || "-"}</p>
                                </Col>
                                <Col span={12}>
                                    <p><strong>Client Name:</strong> {clientName || "-"}</p>
                                </Col>
                                <Col span={12}>
                                    <p><strong>Product:</strong> {productName || "-"}</p>
                                </Col>
                                <Col span={12}>
                                    <p><strong>Sub Product:</strong> {subProductName || "-"}</p>
                                </Col>
                                <Col span={12}>
                                    <p><strong>Status:</strong> {status || "-"}</p>
                                </Col>
                                <Col span={12}>
                                    <p><strong>Date:</strong> {new Date(date).toLocaleDateString() || "-"}</p>
                                </Col>
                                <Col span={12}>
                                    <p><strong>Time:</strong> {new Date(time).toLocaleTimeString() || "-"}</p>
                                </Col>
                                <Col span={12}>
                                    <p><strong>User Sentiment:</strong> {userSentiments || "-"}</p>
                                </Col>
                                <Col span={24}>
                                    <Typography.Text><strong>Summary:</strong></Typography.Text>
                                    <p>{summary || "-"}</p>
                                </Col>
                                <Col span={24}>
                                    <Typography.Text><strong>Transcription:</strong></Typography.Text>
                                    {transcription && transcription.length > 0 ? (
                                        transcription?.map((item, index) => (
                                            <div key={index}>
                                                <Typography.Text><strong>{item.name || "NA"}:</strong> {item.text || "NA"}</Typography.Text>
                                                <Typography.Text className="block text-[gray]">{new Date(item.createdTime).toLocaleString() || "NA"}</Typography.Text>
                                            </div>
                                        ))
                                    ) : (
                                        <Typography.Text className="block" >No transcription data available.</Typography.Text>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )
                )}
            </Card>
        </Modal>
    );
};