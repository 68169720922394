import axios from "axios";
import { finEndpoints } from "../constants/finEndpoints";

export function getActiveClients(userId: string | number, pageNo:number, pageSize:number) {
  const endpoint = `${finEndpoints.ACTIVE_CLIENTS}${userId}&pageNo=${pageNo}&pageSize=${pageSize}`;
  return axios.get(endpoint);
}



export function getClosedClients(userId: string | number, pageNo:number, pageSize:number) {
  const endpoint = `${finEndpoints.CLOSE_CLIENT}${userId}&pageNo=${pageNo}&pageSize=${pageSize}`;
  return axios.get(endpoint);
}

