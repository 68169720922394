import axios from "axios";
import { getUserData } from "../utils";

let requestInterceptor: any;
export const setAxiosConfig = () => {
  const userData = getUserData();
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.interceptors.request.use(
    (config) => {
      const bearerToken = userData?.access_token || "";
      if (bearerToken) {
        config.headers.Authorization = `Bearer ${bearerToken}`;
      }

      config.headers.Accept = "*/*";
      config.headers["ngrok-skip-browser-warning"] = "true";
      config.headers["User-Agent"] = "CustomBrowser/1.0";
      return config;
    },
    (error) => {
      if (error?.response && error?.response?.status === 502) {
        console.error("Server error: 502 Bad Gateway");
        window.location.replace("/maintenance");
      }
      return Promise.reject(error);
    }
  );
};

export const clearAxiosConfig = () => {
  axios.interceptors.request.eject(requestInterceptor);
};
