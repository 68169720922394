import { useQuery } from "@tanstack/react-query";
import { getSidebarMenu } from "../../../services/sidebar";
import { queryKey } from "../../../constants/queryKey";

export const useSidebarMenu = (roleId: number) => {
  return useQuery({
    queryKey: [queryKey.GET_SIDEBAR_MENU, roleId],
    queryFn: () => getSidebarMenu(roleId).then((res) => res.data),
  });
};
