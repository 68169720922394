import { useEffect, useState } from "react";
import { Button, Col, Tabs } from "antd";

import AppLayout from "../Layout";
import { AdminCasesTable } from "../Dashboards/Tables/AdminCasesTable";
import { PhoneOutlined } from "@ant-design/icons";
import { useActiveClients } from "../../reactQuery/hooks/knowledgeBaseHooks/useActiveClients";
import { getUserData } from "../../utils";
import { useClosedClients } from "../../reactQuery/hooks/knowledgeBaseHooks/useClosedClients";

const KnowledgeBase = () => {

    const [activeKey, setActiveKey] = useState("1");
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    const userInfo = getUserData();
    const userId = userInfo?.userId;
    const { data, isLoading: isActiveClientsLoading, refetch: refetchActiveClients, isRefetching: isActiveClientsRefetching } = useActiveClients(userId, activeKey, currentPageNumber, pageSize);
    const { data: closedClientsData, isLoading: isClosedClientsLoading, refetch: refetchClosedClients, isRefetching: isClosedClientsRefetching } = useClosedClients(userId, activeKey, currentPageNumber, pageSize);
    const activeClients = data?.data || [];
    const closedClients = closedClientsData?.data || [];
    useEffect(() => {
        if (activeKey === "1") {
            refetchActiveClients();
        }

    }, [pageSize, currentPageNumber, activeKey, refetchActiveClients])
    useEffect(() => {
        if (activeKey === "2") {
            refetchClosedClients();
        }

    }, [activeKey, refetchClosedClients, pageSize, currentPageNumber])
    useEffect(() => {
        setPageSize(10);
        setCurrentPageNumber(1);
    }, [activeKey])
    const items = [
        {
            label: "Active Leads",
            key: "1",
            children: (
                <AdminCasesTable
                    pageSize={pageSize}
                    data={activeClients}
                    pageNo={currentPageNumber}
                    setPageSize={setPageSize}
                    totalRecords={data?.totalResults}
                    setCurrentPageNumber={setCurrentPageNumber}
                    isPending={isActiveClientsLoading || isActiveClientsRefetching}
                />
            ),
        },
        {
            label: "Closed Leads",
            key: "2",
            children: (
                <AdminCasesTable
                    pageSize={pageSize}
                    data={closedClients}
                    pageNo={currentPageNumber}
                    setPageSize={setPageSize}
                    setCurrentPageNumber={setCurrentPageNumber}
                    totalRecords={closedClientsData?.totalResults}
                    isPending={isClosedClientsLoading || isClosedClientsRefetching}


                />
            ),
        },
    ];


    const emergencyAssistance = (
        <Col>
            <Button icon={<PhoneOutlined />} type="primary" style={{ marginRight: "10px" }} disabled />
            {/* <Button icon={<MessageOutlined />} type="primary" disabled /> */}
        </Col>
    );


    return (
        <AppLayout moduleName="Knowledge Base" extraField={emergencyAssistance}>
            <Tabs
                items={items}
                onChange={(key) => setActiveKey(key)}
                activeKey={activeKey}
            ></Tabs>
        </AppLayout>
    );
};

export default KnowledgeBase;
