import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useStatusList } from "../../reactQuery/hooks/common/useStatusList";

const { Option } = Select;

export function FinAdvanceFilter(props: any) {
  const [form] = Form.useForm();
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(true);
  const [valuesForFilter, setValuesForFilter] = useState<any>({});

  const handleFormChange = () => setIsSubmitBtnDisabled(false);
  const handleFormReset = () => {

    form.resetFields();
    props.handleSearchQueries("", "", "");
    setIsSubmitBtnDisabled(true);

  };

  const { data, isLoading } = useStatusList();
  const statusList = data?.data || [];

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    props.handleSearchQueries(values?.id, values?.clientName, values.status?.value);
    setIsSubmitBtnDisabled(true);
  };

  return (
    <Card
      className="advanceFilter"
      title={"Advance Filter"}
      style={{ width: "100%" }}
      extra={
        <CloseOutlined
          className="text-lg cursor-pointer"
          onClick={() => {
            props.toggleAdvanceFilter();
            handleFormReset();
          }}
        />
      }
    >
      <Form
        layout="vertical"
        name="advance-filter-form"
        form={form}
        onValuesChange={(changedValues, values) => {
          handleFormChange();
          setValuesForFilter(values);
        }}
      >
        <Row gutter={5}>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item label={props.idLabel || "Id"} name="id">
              <Input placeholder="Enter ID" />
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item label={"Client Name"} name="clientName">
              <Input placeholder="Enter Client Name" />
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item label={"Status"} name="status">
              <Select
                labelInValue
                allowClear
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Status"
                maxTagCount={0}
                loading={isLoading}
              >
                {statusList?.map(({ status, statusId }: any) => {
                  return (
                    <Option value={status} key={statusId}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="flex justify-end gap-2">
          <Button onClick={handleFormReset}>Reset</Button>
          <Button
            type="primary"
            disabled={isSubmitBtnDisabled}
            onClick={handleSubmit}
          >
            OK
          </Button>
        </Row>
      </Form>
    </Card>
  );
}