import { useState } from "react";
import { Col, Pagination, Row, Table, Tooltip } from "antd";
import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";

import { knowledgeBaseTableColumns } from "./Columns";
import { AdvanceFilter } from "../../AdvanceFilter";
import { useGlobalStore } from "../../../contexts/StoreContext";
import { downloadCases } from "../../../services/commonController";
import { getCasePayload, getCasePayloadType, retCasesWord } from "../../../utils";


export function AdminCasesTable({
  data,
  pageSize,
  totalRecords,
  isPending,
  status_type,
  setPageSize,
  pageNo,
  setCurrentPageNumber
}: any) {
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const { state, dispatch } = useGlobalStore();

  const toggleAdvanceFilter = () => setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
  const payload = getCasePayload(state);
  const casePayloadType: any = getCasePayloadType(state.activeTabKey);

  const handleOnChange = (page: number, pageSize: number) => {
    dispatch({
      type: casePayloadType,
      payload: {
        ...payload,
        page: page,
        size: pageSize,
      },
    });
    setCurrentPageNumber(page);
    setPageSize(pageSize);
  };

  const handleSorting = (sortBy: string, sortOrder: string) => {
    dispatch({
      type: casePayloadType,
      payload: {
        ...payload,
        sortBy: sortBy || "",
        sortOrder: sortOrder || "",
      },
    });
  };

  const getSource = () => {
    let source = "";
    if (state.activeTabKey === "1") {
      source = "active";
    }
    if (state.activeTabKey === "2") {
      source = "completed";
    }
    return source;
  };

  const source = getSource();

  const handleDownload = (source: string) => {
    downloadCases(source)
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${source}Cases.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the cases:", error);
      });
  };
  return (
    <Col>
      <Table
        loading={isPending}
        onChange={(selectedRowKeys, selectedRows, info: any) => { handleSorting(info?.field, info?.order); }}
        columns={knowledgeBaseTableColumns}
        dataSource={data || []}
        size="middle"
        pagination={false}
        // onRow={(rowInfo) => ({
        //   onClick: () => {
        //     localStorage.setItem("caseType", rowInfo?.bucketName);
        //     const detailsPath = generatePath("/portfolio/:id", { id: rowInfo.key.toString() });
        //     navigate(detailsPath);
        //     dispatch({ type: "SET_BUCKET_NAME", payload: rowInfo?.bucketName });
        //   },
        // })}
        style={{ cursor: "pointer" }}
      />

      <Row className="flex justify-center pt-6 pb-6">
        <Pagination
          current={pageNo}
          showSizeChanger
          pageSize={pageSize}
          total={totalRecords}
          onChange={handleOnChange}
        />
      </Row>

    </Col>
  );
}
