import { useQuery } from '@tanstack/react-query';
import { getChatHistoryDetail } from '../../../services/commonController';
import { queryKey } from '../../../constants/queryKey';

export const useChatHistoryDetail = (userId: string, chatWindowId: string, options:any) => {
    return useQuery({
        queryKey: [queryKey.GET_CHAT_HISTORY_DETAIL, userId, chatWindowId],
        queryFn: () => getChatHistoryDetail(userId, chatWindowId).then(res => res.data),
        ...options
    });
};